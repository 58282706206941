<template>
  <v-container fluid id="viewCliente">
    <base-material-card>
      <v-row justify="center" align="center">
        <v-col cols="12" md="12" xl="12">
          <v-btn
            color="primary"
            @click.native="$router.push({ name: 'customerList' })"
            style="height: 30px"
            v-if="!$vuetify.breakpoint.xsOnly"
            outlined
          >
            <v-icon>mdi-arrow-left</v-icon>
            {{ $t("back") }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" xl="6">
          <customer-card
            :customer="customer"
            :studio="items.length ? items[0].studio.studio_name : null"
            :edit="can_edit_customer"
          >
            <template
              v-if="
                $store.getters['auth/isStudio'] &&
                customer.relations &&
                customer.relations.length >= 1
              "
            >
              <tr
                v-if="
                  (customer.relations[0].from === 'tattooer' &&
                    customer.relations[0].tattooer != null) ||
                  customer.relations[0].from === 'studio'
                "
              >
                <td style="color: var(--v-gris1-base); padding: 3px 8px">
                  {{ $t("customers.contact") }}
                </td>
                <td style="padding: 3px 8px">
                  {{
                    customer.relations[0].from == "tattooer"
                      ? $tc("artist")
                      : $tc(customer.relations[0].from)
                  }}<span
                    v-if="
                      customer.relations[0].from === 'tattooer' &&
                      customer.relations[0].tattooer != null
                    "
                    >: {{ customer.relations[0].tattooer.user.first_name }}
                    {{ customer.relations[0].tattooer.user.last_name }}</span
                  >
                </td>
              </tr>
            </template>
          </customer-card>
        </v-col>
        <v-col cols="12" md="6" xl="3">
          <expenses-table
            outlined
            ref="expensesTableCustomer"
            type="customer"
            :customer="customer"
            :title="$t('expenses.total_sum')"
            height="150px"
            class="mt-2"
          ></expenses-table>
          <v-card elevation="6" style="height: 250px" v-if="false">
            <v-row
              justify="end"
              style="position: absolute; top: 0px; right: 10px"
              v-if="type != 'total'"
            >
              <v-btn
                style="margin-top: -2px"
                icon
                @click="
                  $store.state.customers.hide
                    ? ($store.state.customers.hide = false)
                    : ($store.state.customers.hide = true)
                "
              >
                <v-icon size="25">$hide</v-icon></v-btn
              >
            </v-row>
            <v-card-title
              style="justify-content: center; text-transform: uppercase"
            >
              <h2
                class="my-3"
                style="text-transform: uppercase; font-weight: normal"
              >
                Suma total
              </h2>
            </v-card-title>
            <v-card-content align="center">
              <v-card-text
                style="font-size: 50px; margin-top: 5%; font-weight: bold"
                v-if="!$store.state.customers.hide"
              >
                <v-btn
                  style="margin-top: 3%"
                  outlined
                  @click="$store.state.customers.hide = true"
                >
                  {{ $t("showDetails") }}</v-btn
                >
              </v-card-text>
              <v-card-text
                v-else
                v-bind:style="[{ 'font-size': totalPriceSice }]"
                style="font-size: 50px; margin-top: 10%; font-weight: bold"
              >
                {{ this.totalPrice }}€
              </v-card-text>
            </v-card-content>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" md="10" xl="8">
          <v-data-table
            id="customer_appointments"
            :footer-props="{
              'items-per-page-text': $vuetify.breakpoint.smAndDown
                ? ''
                : $t('items_per_page'),
            }"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
          >
            <template v-slot:item.customer="{ item }"
              >{{ item.customer.user.first_name }}
              {{ item.customer.user.last_name }}</template
            >
            <template v-slot:item.tattooer="{ item }">
              <template v-if="item.tattooer">
                {{ item.tattooer.user.first_name }}
                {{ item.tattooer.user.last_name }}
              </template>
              <template v-else> - </template>
            </template>
            <template v-slot:item.studio="{ item }">{{
              item.studio.studio_name
            }}</template>
            <template v-slot:item.date="{ item }">
              <span v-if="item.available_dates.length">
                {{
                  $d(
                    new Date(item.available_dates[0].date.substring(0, 10)),
                    "date2digits"
                  )
                }}
                {{ item.available_dates[0].date.substring(11, 16) }}
              </span>
              <span v-else> - </span>
            </template>
            <template v-slot:item.price="{ item }">{{
              finalPrice(item)
            }}</template>
            <template v-slot:item.from="{ item }">{{
              $tc(item.from == "tattooer" ? "artist" : item.from)
            }}</template>
            <template v-slot:item.allergies="{ item }">{{
              item.allergies || "-"
            }}</template>
            <template v-slot:item.actions="{ item }"
              ><v-btn
                icon
                @click="
                  $router.push({
                    name: 'appointmentView',
                    params: { id: item.id },
                  })
                "
              >
                <v-icon small>$eye</v-icon></v-btn
              ></template
            >
          </v-data-table>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CustomerView",
  data() {
    return {
      customer: {},
      items: [],
      options: {},
      totalPrice: 0,
    };
  },
  components: {
    CustomerCard: () => import("@/components/customer/Card"),
    ExpensesTable: () => import("@/components/expense/Table"),
  },
  watch: {
    options: {
      handler() {
        this.fetchAppointments();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchCustomer();
    this.fetchAppointments();
    this.fetchAppointmentsTotalPriceByCustomerId();
  },
  methods: {
    finalPrice(appoinment) {
      let price = 0;
      appoinment.available_dates.forEach((x) => {
        if (
          x.type === "appointment_day" ||
          x.type === "appointment_day_session"
        ) {
          price += x.sesion_price;
        }
      });
      if (price != 0) return this.$n(price, "currency");
      return "-";
    },
    ...mapActions("customers", ["getCustomer"]),
    ...mapActions("appointments", [
      "getAppointments",
      "getAppointmentsByCustomerId",
    ]),

    fetchCustomer() {
      this.getCustomer(this.$route.params.id).then((customer) => {
        this.customer = customer;
        setTimeout(() => {
          this.$refs.expensesTableCustomer.fetchExpenses();
          this.$refs.expensesTableCustomer.fetchAmount();
        }, 1000);
        //console.log(this.customer);
      });
    },

    fetchAppointments() {
      this.loading = true;
      let filters = { customer_id: this.$route.params.id };
      if (this.$store.getters["auth/isTattooer"])
        filters.tattooer_id = this.$store.state.auth.user.tattooer.id;
      if (this.$store.getters["auth/isStudio"])
        filters.studio_id = this.$store.state.auth.user.studio.id;
      this.getAppointments({
        pagination: this.options,
        filters,
      }).then((appointments) => {
        this.items = appointments.data;
        console.log(this.items);
        this.totalAppointments = appointments.total;
        this.loading = false;
      });
    },
    fetchAppointmentsTotalPriceByCustomerId() {
      this.loading = true;
      //console.log("fetch");
      this.getAppointmentsByCustomerId({
        customer_id: this.$route.params.id,
      }).then((customerPrices) => {
        //console.log("inside fetch");
        console.log(customerPrices);
        this.appointmentsByCust = customerPrices;
        this.appointmentsByCust.forEach((x) => {
          x.available_dates.forEach((element) => {
            if (
              element.type === "appointment_day" ||
              element.type === "appointment_day_session"
            ) {
              this.totalPrice += element.sesion_price;
            }
          });
        });

        this.loading = false;
      });
    },
  },
  computed: {
    limitations() {
      if (this.customer) {
        console.log("customer");
        if (this.customer.relations) {
          console.log("customer.relations");
          console.log(this.customer.relations);
          let r = this.customer.relations.find((r) => {
            console.log(r.tattooer_id, this.$store.state.auth.user.tattooer.id);
            return r.tattooer_id === this.$store.state.auth.user.tattooer.id;
          });

          if (r) {
            console.log("r", r);
            let ts = r.tattooer.tattooer_studios.find(
              (ts) =>
                ts.tattooer_id === r.tattooer_id && ts.studio_id === r.studio_id
            );
            if (ts && ts.limitations) {
              return ts.limitations;
            }
          }
        }
      }
    },
    can_edit_customer() {
      if (this.$store.getters["auth/isTattooer"]) {
        if (this.limitations) {
          return this.limitations.customer_edit;
        }
      }
      return true;
    },
    totalPriceSice() {
      if (Math.abs(this.totalPrice) > 10000) {
        return "40px";
      } else if (Math.abs(this.totalPrice) > 100000) {
        return "30px";
      } else return "50px";
    },
    headers() {
      if (this.$store.getters["auth/isTattooer"]) {
        return [
          {
            text: this.$tc("customer"),
            align: "center",
            sortable: false,
            value: "customer",
          },
          {
            text: this.$tc("studio"),
            align: "center",
            sortable: false,
            value: "studio",
          },
          {
            text: this.$t("appointments.price"),
            align: "center",
            sortable: false,
            value: "price",
          },
          {
            text: this.$tc("appointment"),
            align: "center",
            sortable: false,
            value: "date",
          },
          {
            text: this.$t("appointments.from"),
            align: "center",
            sortable: false,
            value: "from",
          },
          {
            text: this.$t("actions"),
            align: "center",
            sortable: false,
            value: "actions",
          },
        ];
      }
      return [
        {
          text: this.$tc("customer"),
          align: "center",
          sortable: false,
          value: "customer",
        },
        {
          text: this.$tc("artist"),
          align: "center",
          sortable: false,
          value: "tattooer",
        },
        {
          text: this.$t("appointments.price"),
          align: "center",
          sortable: false,
          value: "price",
        },
        {
          text: this.$tc("appointment"),
          align: "center",
          sortable: false,
          value: "date",
        },
        {
          text: this.$t("appointments.from"),
          align: "center",
          sortable: false,
          value: "from",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },
};
</script>


<style  lang="sass">
#viewCustomer
  h2
    font-weight: normal
    font-size: 20px

  .v-data-table
    td
      padding: 3px 8px !important
    td:first-child
      color: var(--v-gris1-base)

@media (min-width: 700px)
  #customer_appointments
    td
      &:nth-child(1),
      &:nth-child(2)
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden

      &:nth-child(1)
        max-width: 115px

      &:nth-child(2)
        max-width: 115px
</style>