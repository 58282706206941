var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","id":"viewCliente"}},[_c('base-material-card',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12","xl":"12"}},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticStyle:{"height":"30px"},attrs:{"color":"primary","outlined":""},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'customerList' })}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('customer-card',{attrs:{"customer":_vm.customer,"studio":_vm.items.length ? _vm.items[0].studio.studio_name : null,"edit":_vm.can_edit_customer}},[(
              _vm.$store.getters['auth/isStudio'] &&
              _vm.customer.relations &&
              _vm.customer.relations.length >= 1
            )?[(
                (_vm.customer.relations[0].from === 'tattooer' &&
                  _vm.customer.relations[0].tattooer != null) ||
                _vm.customer.relations[0].from === 'studio'
              )?_c('tr',[_c('td',{staticStyle:{"color":"var(--v-gris1-base)","padding":"3px 8px"}},[_vm._v(" "+_vm._s(_vm.$t("customers.contact"))+" ")]),_c('td',{staticStyle:{"padding":"3px 8px"}},[_vm._v(" "+_vm._s(_vm.customer.relations[0].from == "tattooer" ? _vm.$tc("artist") : _vm.$tc(_vm.customer.relations[0].from))),(
                    _vm.customer.relations[0].from === 'tattooer' &&
                    _vm.customer.relations[0].tattooer != null
                  )?_c('span',[_vm._v(": "+_vm._s(_vm.customer.relations[0].tattooer.user.first_name)+" "+_vm._s(_vm.customer.relations[0].tattooer.user.last_name))]):_vm._e()])]):_vm._e()]:_vm._e()],2)],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('expenses-table',{ref:"expensesTableCustomer",staticClass:"mt-2",attrs:{"outlined":"","type":"customer","customer":_vm.customer,"title":_vm.$t('expenses.total_sum'),"height":"150px"}}),(false)?_c('v-card',{staticStyle:{"height":"250px"},attrs:{"elevation":"6"}},[(_vm.type != 'total')?_c('v-row',{staticStyle:{"position":"absolute","top":"0px","right":"10px"},attrs:{"justify":"end"}},[_c('v-btn',{staticStyle:{"margin-top":"-2px"},attrs:{"icon":""},on:{"click":function($event){_vm.$store.state.customers.hide
                  ? (_vm.$store.state.customers.hide = false)
                  : (_vm.$store.state.customers.hide = true)}}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("$hide")])],1)],1):_vm._e(),_c('v-card-title',{staticStyle:{"justify-content":"center","text-transform":"uppercase"}},[_c('h2',{staticClass:"my-3",staticStyle:{"text-transform":"uppercase","font-weight":"normal"}},[_vm._v(" Suma total ")])]),_c('v-card-content',{attrs:{"align":"center"}},[(!_vm.$store.state.customers.hide)?_c('v-card-text',{staticStyle:{"font-size":"50px","margin-top":"5%","font-weight":"bold"}},[_c('v-btn',{staticStyle:{"margin-top":"3%"},attrs:{"outlined":""},on:{"click":function($event){_vm.$store.state.customers.hide = true}}},[_vm._v(" "+_vm._s(_vm.$t("showDetails")))])],1):_c('v-card-text',{staticStyle:{"font-size":"50px","margin-top":"10%","font-weight":"bold"},style:([{ 'font-size': _vm.totalPriceSice }])},[_vm._v(" "+_vm._s(this.totalPrice)+"€ ")])],1)],1):_vm._e()],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10","xl":"8"}},[_c('v-data-table',{attrs:{"id":"customer_appointments","footer-props":{
            'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
              ? ''
              : _vm.$t('items_per_page'),
          },"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(item.customer.user.first_name)+" "+_vm._s(item.customer.user.last_name))]}},{key:"item.tattooer",fn:function(ref){
          var item = ref.item;
return [(item.tattooer)?[_vm._v(" "+_vm._s(item.tattooer.user.first_name)+" "+_vm._s(item.tattooer.user.last_name)+" ")]:[_vm._v(" - ")]]}},{key:"item.studio",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(item.studio.studio_name))]}},{key:"item.date",fn:function(ref){
          var item = ref.item;
return [(item.available_dates.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$d( new Date(item.available_dates[0].date.substring(0, 10)), "date2digits" ))+" "+_vm._s(item.available_dates[0].date.substring(11, 16))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.price",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm.finalPrice(item)))]}},{key:"item.from",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm.$tc(item.from == "tattooer" ? "artist" : item.from)))]}},{key:"item.allergies",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(item.allergies || "-"))]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'appointmentView',
                  params: { id: item.id },
                })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$eye")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }